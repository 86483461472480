import React from 'react'
import styled from 'styled-components'

import { Layout } from '../../components/layout'
import { Footer } from '../../components/footer'
import { Teaser } from '../../components/teaser'

import { Image } from '../../components/common/image'
import { Grid, Row, Col } from '../../components/common/grid'
import { Space } from '../../components/common/space'
import { H4 } from '../../components/common/heading'

const List = styled.ul`
  margin: 0;
`

const ListItem = styled.li`
  color: #69707b;
  text-align: justify;
  margin-bottom: 10px;
`

const title = 'Chimpanzee Energy Chews'
const description = 'Frucht-Gummibärchen mit komplexen Kohlenhydraten'
const keywords = ''

const PropteinRiegel = () => (
  <Layout title={title} description={description} keywords={keywords}>
    <Teaser title={title} description={description} imageName="energy_chews" />

    <Grid>
      <Row centered>
        <Col xs={12} md={9}>
          <div>
            <Space bottom={20}>
              <H4>Energy Chews (50g))</H4>
            </Space>
            <p>
              Vegane Frucht-Gummibärchen mit komplexen Kohlenhydraten und hohem
              Fruchtanteil. Die weichen, einfach zu kauenden Chimpanzee Energy
              Chews sind ein ausgezeichneter Energielieferant bei physischer
              Belastung oder zur Konzentrationssteigerung.
            </p>

            <p>Eine natürliche Quelle für schnelle Energie!</p>

            <Space top={50} bottom={20}>
              <H4>Geschmacksrichtungen</H4>
            </Space>

            <List>
              <ListItem>Waldbeeren</ListItem>
            </List>
          </div>
        </Col>
      </Row>

      <Space top={50} bottom={20}>
        <Row centered>
          <Col xs={12} md={9}>
            <H4>Produktbeispiele</H4>
          </Col>
        </Row>
      </Space>

      <Row centered>
        <Col xs={12} sm={4} md={3} bottom={50}>
          <Image name="energy_chews_waldbeere" />
        </Col>
        <Col xs={12} sm={4} md={3} bottom={50} />
        <Col xs={12} sm={4} md={3} bottom={50} />
      </Row>
    </Grid>

    <Footer bottom={50} />
  </Layout>
)

export default PropteinRiegel
